import CONSTANTS, { COMPONENT_TYPES } from "../src/constants"

export const dataUrl = "/content/kohler-india/in/en.model.json"
export const contactUsUrl = "/support/contact.model.json"
export const findAStoreUrl = "/locations/find-a-store.model.json"
export const diwaliSaleUrl = "/p/promotions/diwali-sale.model.json"
export const blogUrl = "/blog.model.json"
export const downloadFileURL = "/crm/asset/download"
export const productEnquiryUrl = "/place-an-enquiry.model.json"
export const dealerEnquiryURL = "/dealership-enquiry.model.json"
export const storeLandingURL =
  "/locations/find-a-store/kohler-experience-centres.model.json"
export const returnsUrl = "/returns.model.json"
export const orderDetailsPageDataUrl = "/orders/order-details.model.json"
export const orderConfirmationDataUrl = "/orders/order-confirmation.model.json"
export const orderHistoryPageDataUrl = "/orders/order-history.model.json"
export const orderStatusPageDataUrl = "/orders/order-status.model.json"
export const REGISTER_OFFLINE_PRODUCTS_DATA_URL = `/my-account/product-registration/register-offline-product.model.json`
export const REGISTER_ONLINE_PRODUCTS_DATA_URL = `/my-account/product-registration/register-online-product.model.json`
export const pressroomUrl = "/press-room.model.json"
export const returnConfirmationUrl = "/return-confirmation.model.json"
export const returnRequestUrl = "/return-request.model.json"
export const dealerEnquiryCitiesURL =
  "/etc/acs-commons/lists/Kohler/in/en/dealership/cities/jcr:content.list.json"
export const headerUrl =
  "/content/experience-fragments/kohler-india/india/en/navigation/header/header_qa_testing/global-header-experience-fragment/master"
export const footerUrl =
  "/content/experience-fragments/kohler-india/india/en/navigation/footer/Global-footer-experience-fragment/master1"
export const cartItemsEndPoint = "/cart/items"
export const addFavoritesEndPoint = id => {
  return `/shopping-list/add/${id}?type=product`
}
export const favoritesEndPoint =
  "/shopping-list/Favorites?lang=" + process.env.NEXT_PUBLIC_LOCALE
export const addressEndPoint = "/cart/address"
export const GLOBAL_CONFIGURATION_PATH =
  "/bin/kohler/services/globalconfig.kohler-india.json"
export const emailSubscribeEndPoint = "/clevertap/newsletter/subscription"
export const FIND_MY_ORDERS_ENDPOINT = "/checkout/orders/v1/query"
export const FIND_MY_ORDERS_ENDPOINT2 = "/checkout/orders/query"
export const DEFAULT_CARD_DETAILS_API_PATH =
  "/bin/kohler/services/list?path=/content/kohler-india"
export const PDP_END_POINT_SUBSCRIPTION_KEY = "/lw/pdp/product"
export const PRODUCT_DATA_URL = `/p/product-detail-page.model.json`
export const PHONE_REGEX = /^[6-9]{1}[0-9]{9}$/
export const cartDataUrl = "/cart.model.json"
export const cartPage = "/cart"
export const HOMEPAGEURL = "/content/kohler-india/in/en.html"
export const checkoutDataUrl = "/checkout.model.json"
export const panConsentEndPoint = id =>
  `/cart/items?cartId=${id}&expand=calculation`
export const orderSubmitEndPoint = "/checkout/order/submit"
export const PAYMENT_URL = "/payment/ccavenue/iframe"
export const DECRYPT_PAYMENT_RESP = "/payment/ccavenue/decrypt"
export const PAY_BY_LINK_URL = "/ccavenue/paybylink?channel=india"
export const checkDuplicacyUrl = "/customer/signup?stage=initial"
export const checkDuplicacyUrlForUpdateNumber = "/customer/users"
export const resetFactorUrl = "/customer/factor/reset"
export const enrollFactorUrl = "/customer/factor/enroll"
export const updateNumberUrl = "customer/profile"
export const sendTriggerURL = "clevertap/events"
export const GET_ORDER_ENDPOINT = orderId => `/checkout/${orderId}`
export const PLP_END_POINT_CLIENT = "/search/plp"
export const apimAemURL = "/aem/resource?resourcepath="
export const ORDER_INVOICE_URL = (invoiceNo, orderNo) =>
  `/checkout/order/invoice?filename=invoice_${invoiceNo}.pdf&orderNumber=${orderNo}`
export const CREDIT_NOTE_URL = (creditNo, orderNo) =>
  `/checkout/order/invoice?filename=creditNote_${creditNo}.pdf&orderNumber=${orderNo}`
export const brandTransactSwitcher =
  process.env.NEXT_PUBLIC_BRAND_TRANSACT_SWITCHER
export const resourceFacetLabels = (persona = "GST") => [
  "masterSKU_s",
  "sourceId_s",
  "masterCustomerFacingSKU_s",
  "SKUColorSwatchFilename_ss",
  "ProductDescriptionProductShort_s",
  "ProductBrandName_s",
  "metaTitle_s",
  "productName_s",
  "RegionBrandName_s",
  "id",
  "productImages.url_s",
  "productImages.url_ss",
  "slug_s",
  "Product_Category",
  "displayPriceStartingAt_s",
  "Collapse_Title_s",
  "Collapse_Description_s",
  `priceList.${persona}.price_d`,
  `priceList.${persona}.finalPrice_d`,
  `priceList.${persona}.discountedPrice_d`,
  `priceList.${persona}.saleOffer_s`,
  `priceStartingAt.${persona}_d`,
  `discountedPriceStartingAt.${persona}_d`,
  "variantList.sku_ss",
  "variantList.sku_s",
  "SKUColorFinishName_s",
  "SKUColorFinishName_ss",
  "Color.SKU.Details_ss",
  "Color.SKU.Details_s",
  `Color.${persona}.Details_ss`,
  "ProductExclusive_s",
  "RegionReleaseforShipment_dt",
  "productImages.labelWithUrl_ss",
  "productImages.labelWithUrl_s",
  "productImageVariantImages.labelWithUrl_ss",
  "RegionOnBackOrder_s",
  "comboSKUAvailable_b",
  "ProductBrandNameDisplay_s",
  "ctId_s",
  "ProductResource.InteractiveSVG.resourceName_ss",
  "ProductProductNo_s",
  "ProductResource.InteractiveSVG.resourceTitle_ss",
  "ProductServices_s",
  "InstallationAvailable_s",
  `priceList.ANY.price_d`,
  "RegionSupplyChain_ss",
  "BundleList_ss",
  "bundleNameSlugDetails_ss",
]

const constUpdates = Object.assign(CONSTANTS, {
  REGEX_NUMBER: /^[0-9]*$/,
  REGEX_PHONE_NUMBER: /^[6-9]{1}[0-9]{9}$/,
  REGEX_USER_TEXT: /^[a-zA-Z-,'\s]+$/,
  INVALID_OTP: "Invalid code. Try again.",
  SESSION_EXPIRED: "Your session has expired. Please try to sign in again.",
  TOO_MANY_ATTEMPTS: "Too many attempts. Try again later.",
  CURRENCY_SIGN: "₹",
  KEMP_PERSONA: "IN_Kohler_Registered_Consumer:KEMP",
})

const compTypeUpdates = Object.assign(COMPONENT_TYPES, {
  FIND_A_STORE: "find-a-store-detail",
  CUSTOMER_SUPPORT: "kohler-india/components/customersupport",
  DEALER_ENQUIRY: "Dealership Enquiry",
  PRODUCT_ENQUIRY: "Product Enquiry",
  RETURNS: "Returns",
  RETURN_CONFIRMATION: "Return Confirmation",
  RETURN_REQUEST: "Return Request",
  FIND_BY_PINCODE: "/maps/get-city-state-with-pincode?pincode=",
  PRESS_LANDING: "press-release-landing",
  LIST: "/list/v1/list",
  BLOG_PAGE: "blogtab",
  LEAD_GENERATION: "leadgenerationform",
})

export const ESTIMATED_TOTAL_FOR_PAN_CONSENT = 200000
export const ESTIMATED_TOTAL_FOR_NO_COST_EMI = 10000
export const cancellationReason =
  "Cancel reason is not specified by the customer."
export const pdpResourceFacetLabels = ["ProductIsExclusive_s"]

export const SEARCH_PDP_FL = [
  "CustomerFacingSKU_s",
  "ProductProductRecommendedAccessories_ss",
  "ProductProductRecommendedAccessories_s",
  "ProductBrandNameDisplay_t",
  "ProductServices_ss",
  "ProductPriceSpiderIncluded_s",
  "SKUIsProjectPack_s",
  "Collapse_Field_s",
  "Color.SKU.Details_ss",
  "Color.SKU.Details_s",
  "ProductYoutubeID_s",
  "productRegionAllVariantDiscontinued_b",
  "ProductOverallLengthInches_s",
  "ProductOverallWidthInches_s",
  "ProductOverallHeightInches_s",
  "ProductWebFeatures_ss",
  "ProductFreightPolicyType_s",
  "priceList.*.finalPrice_d",
  "priceList.*.saleOffer_s",
  "ProductBadgeInfo_s",
  "ProductBadgeInfo_ss",
  "ProductWebFeatures_s",
  "ProductWebTechnology_ss",
  "ProductWebTechnology_s",
  "ProductNarrativeDescription_s",
  "ProductWebMaterial_ss",
  "ProductWebMaterial_s",
  "ProductWebInstallation_ss",
  "ProductWebInstallation_s",
  "ProductCaliforniaProp65WarningDisclaimer_ss",
  "ProductCaliforniaProp65WarningDisclaimer_s",
  "ProductWebHydrotherapy_s",
  "ProductWebHydrotherapy_ss",
  "ProductWebRebates_ss",
  "ProductWebRebates_s",
  "SKUDiscontinuedDate_dt",
  "ProductResource.YouTubeInstallVideo.resourceName_ss",
  "ProductResource.YouTubeInstallVideo.resourceTitle_ss",
  "ctProductType_s",
  "CustomerFacingBrand_s",
  "isCollapsedPDP_b",
  "productInRiverId_s",
  "sku_s",
  "SKUCountryofOrigin_s",
  "ProductOverallLengthCm_d",
  "ProductOverallWidthCm_d",
  "ProductOverallHeightCm_d",
  "ProductPackageContents_s",
  "InstallationCost_s",
  "RequiredAccessoryGroupName_ss",
  "RequiredAccessoryGroupProduct_ss",
  "RequiredAccessoryGroupProduct",
  "Recommended_Accessories_ss",
  "RegionSoldOnline_b",
  "redirectUrl_s",
  "productDiscontinued_b",
  pdpResourceFacetLabels.join(","),
  resourceFacetLabels().join(","),
  "title_s",
  "ProductResource.SpecPDFFileName.resourceFullWebURL_ss",
  "ProductResource.SpecPDFFileName.resourceTitle_ss",
  "ProductResource.EnvironProdDecl.resourceFullWebURL_ss",
  "ProductResource.EnvironProdDecl.resourceTitle_ss",
  "ProductResource.HomeownersGuide.resourceFullWebURL_ss",
  "ProductResource.HomeownersGuide.resourceTitle_ss",
  "ProductResource.InstallationWithoutSPPDF.resourceFullWebURL_ss",
  "ProductResource.PartsPDFFileName.resourceFullWebURL_ss",
  "ProductResource.InstallationWithoutSPPDF.resourceTitle_ss",
  "ProductResource.DXFPlanView.resourceFullWebURL_ss",
  "ProductResource.DWGPlanView.resourceFullWebURL_ss",
  "ProductResource.DXFFrontView.resourceFullWebURL_ss",
  "ProductResource.DWGFrontView.resourceFullWebURL_ss",
  "ProductResource.DXFSideView.resourceFullWebURL_ss",
  "ProductResource.DWGSideView.resourceFullWebURL_ss",
  "ProductResource.3DDXFSymbol.resourceFullWebURL_ss",
  "ProductResource.3DDWGSymbol.resourceFullWebURL_ss",
  "ProductResource.3DRevit.resourceFullWebURL_ss",
  "ProductResource.3D3DS.resourceFullWebURL_ss",
  "ProductResource.3DSketchup.resourceFullWebURL_ss",
  "ProductResource.CutOutDXF.resourceFullWebURL_ss",
  "ProductResource.3DOBJ.resourceFullWebURL_ss",
]

export const staticClasses = {
  CONTAINER: "container responsivegrid",
  ONETRUST_ANALYTICS: "",
}

export const paymentSubscriptionKey =
  process.env.NEXT_PUBLIC_PAYMENT_SUBSCRIPTION_KEY
export const PDP_SLIDER_SIZES = {
  largeIpadSize: 1024,
  mobileSize: 767,
  desktopSliderImages: 15,
  smallesIpadSliderImages: 12,
  imageReelTitle: "image reel",
}
export * from "../src/constants"
export { constUpdates as CONSTANTS, compTypeUpdates as COMPONENT_TYPES }
export default CONSTANTS
